<template>
<div>
    <div class="login-div">
        <div class="row">
            <div class="col-lg-8 col-12">

            </div>
            <div class="col-lg-4 col-12 login-container text-center">

                <img src="../../assets/images/logo2.png" height="50" class="mt-60">
                <div class="" style="min-height:70vh; display:flex; align-items:center; flex-flow:column; justify-content:center;">

                    <p class="mb-4" v-if="errorMessage">{{errorMessage}}</p>
                    <!-- <div class="form-group" v-if="errorMessage">
                        <button type="button" class="submit-btn" @click="verifyLink">
                             {{ loader ? '' : 'Resend SMS' }}
                         
                                 <span class="loader mt-1" v-if="loader"></span>
                        </button>
                       </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import trans from '../../../translations'
import {
    mapState
} from "vuex";
import http from "../../http/index";
import api from "../../apis/index";
export default {
    data() {

        return {

            tran: {},
            errorMessage:false,
            loader:false
        }
    },

methods:{

  verifyLink() {
          
            let vm = this
        this.loader = true
            http.post(api.verifyEmailToken, this.$route.query)
                .then(response => {
    vm.loader = false
                    let resetPinObject = {

                        authToken:response.data.data.auth_token,
                        sms_expiry:response.data.data.sms_expiry,
                        phone_last_2d:response.data.data.phone_last_2d
                    }
           localStorage.setItem("resetPinObject", JSON.stringify(resetPinObject));
     
                     
                            this.$helpers.log(response);

                     this.$router.push({
        path:  "/change/pin" ,
        query: { phone_last_2d: response.data.data.phone_last_2d },
      });
                })
                .catch(error => {
                  vm.loader = false
                    this.$helpers.log(error.response);
                    vm.errorMessage = error.response.data.message
                     this.$helpers.log(error.response);
                            if (error.response !== undefined) {
                                this.$toast.warn({
                                    title: this.tran.sorry,
                                    icon: require("../../assets/images/cautionicon.png"),
                                    timeOut: 8000,
                                    message: error.response.data.message,
                                    position: "top center"
                                });
                            }
                });

        },
},


    computed: {
        ...mapState({

            formdata: state => state.auth.email,

        }),
    },
    beforeMount() {

        this.verifyLink()

        this.$helpers.log(this.$route.query, 'requid query')
      localStorage.setItem("activatorQuery", JSON.stringify(this.$route.query));
        const routeToken = this.$route.query.ua;
        //now dispatch action
      

    },
       computed: {
        ...mapState({
            formdata: state => state.auth.signUp,
         
            token: state => state.auth.token
        }),
    },
    mounted() {


        this.tran = trans
      
    }
}
</script>

<style>

</style>
