<template>
    <BeforeUpdatingPin/>
</template>

<script>
import BeforeUpdatingPin from '../../components/auth/BeforeUpdatingPin'
export default {

    components: {
        BeforeUpdatingPin
    }
}
</script>

<style>

</style>
